<template>
    <div>
        <mega-modal ref="modal-2" class="modal-sm"
                    :active="modal"
                    @onClose="close">

            <div v-if="modal">

                <mega-header :title="`${ this.$t('ref_edit_title') } #${ this.refImage.idt_ref_image }`" class="bg-lighter">
                    <span class="text-muted">{{ $t('ref_sub_title') }}</span>
                </mega-header>

                <div class="row p-3">
                    <div class="sm-down:col-12 md-up:col-6">
                        <div class="card-body">
                            <mega-drop-zone @change="renderFile" :type="['image/jpeg', 'image/png']">
                                <mega-image class="image bg-contain" ratio="16x9" :src="preview"/>
                            </mega-drop-zone>
                        </div>

                        <div class="card-body">

                            <mega-input :label="$t('new_model_name')" v-model="refImage.name"/>

                            <mega-textarea :label="$t('desc')" v-model="refImage.description"/>

                        </div>
                    </div>

                    <div class="sm-down:col-12 md-up:col-6">
                        <div class="card-body">

                            <mega-range class="bg-primary mb-0" :label="$t('ref_pic_width')" v-model="refImage.width" :min="10" :max="1000" :step="5"/>
                            <div class="d-flex justify-content-between text-muted">
                                <small>10</small>
                                <small>1000</small>
                            </div>

                            <mega-number :min="10" :max="1000" :step="5" v-model="refImage.width" :help="refImage.width+'cm'"/>

                            <mega-switch class="w-100 mt-3 bg-success" :label="$t('ref_global_loc')"
                                        v-model="refImage.is_global"/>
                        </div>
                    </div>

                    <div class="card-body pt-0 col-12">

                        <map-widget 
                            ratio="16x9"
                            get-location
                            v-if="!refImage['is_global'] && refImage.lat"
                            :images="ref_images"
                            @update="updatePosition"
                            :is-global="refImage.is_global"
                            :lat="refImage.lat || 0"
                            :lng="refImage.lng || 0"/>
                        
                    </div>

                    <div class="card-body col-12">
                        <div class="navbar">

                            <mega-button class="btn-danger btn-sm mr-auto" @click="delete_image">{{ $t('del') }}</mega-button>

                            <div class="navbar-group ml-auto">
                                <mega-button class="btn-white btn-sm mr-2" @click="close">{{ $t('close') }}</mega-button>
                                <mega-button class="btn-success btn-sm" :disabled="!refImage.name || !refImage.width" @click="submit">{{ $t('save') }}</mega-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mega-modal>

        <delete-confirm ref="delete-confirm"
                        :title="$t('ref_del')"
                        @confirm="deleteImage"
                        :text="`${ this.$t('ref_del_confirm') }: ${ refImage.name }`"/>
    </div>
</template>

<script>
    import axios from 'axios'

    import DeleteConfirm from '../../../components/delete-confirm'
    import MapWidget from './map'

    export default {
        data() {
            return {
                refImage: {},
                modal: false,
                preview: undefined,
                file: undefined
            }
        },
        computed: {
            ref_images() {

                let images = this.$parent.ref_images || [];

                return images.reduce((result, img) => {

                    let position = {lng: img.lng, lat: img.lat};

                    if (this.refImage.idt_ref_image !== img.idt_ref_image)
                        result.push({position, is_global: img.is_global});

                    return result;
                }, []);
            }
        },
        methods: {

            submit() {

                let formData = new FormData();
                formData.append("idt_ref_image", this.refImage.idt_ref_image);
                formData.append("name", this.refImage.name);
                formData.append("description", this.refImage.description);
                formData.append("width", this.refImage.width);
                formData.append("is_global", this.refImage['is_global']);

                formData.append("lng", this.refImage.lng);
                formData.append("lat", this.refImage.lat);

                if(this.file) formData.append("image", this.file);

                this.$api.v2.put('/refImage', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(() => { this.close() })
            },

            delete_image() { this.$refs['delete-confirm'].open() },
            deleteImage(modal) {

                this.$api.v2.delete('/refImage', {params: {idt_ref_image: this.refImage.idt_ref_image}})
                    .then(() => {
                        this.close();
                        modal.close();
                    })
                    .catch(() => { this.$alert.danger('Oops.. Server error') })
            },

            open(id) {
                this.refImage.idt_ref_image = id;
                this.$api.v2.get('/refImage', {params: {idt_ref_image: id}})
                    .then(response => response.data['ref_image'])
                    .then(ref_image => {
                        this.refImage = ref_image;
                        this.preview = ref_image['url'];

                        document.body.style.overflowY = 'hidden';
                        this.modal = true;
                    })
            },

            close() {
                this.modal = false;
                this.$emit('close');
                document.body.style.overflowY = 'auto';
            },

            renderFile(file) {

                this.file = file;

                let reader = new FileReader();
                reader.readAsDataURL(this.file);
                reader.onloadend = () => {
                    this.preview = reader.result;
                }
            },

            updatePosition(location) {
                this.refImage.lat = location.lat;
                this.refImage.lng = location.lng;
            }
        },
        components: {
            DeleteConfirm,
            MapWidget
        }
    }
</script>